import React, { Component, useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FormFeedback, Input } from 'reactstrap';
import objectPath from 'object-path';
import postal from 'postal';
import moment from 'moment';

import { encrypt } from '../../components/utils';
import { userAuth, userAuthByUrl } from '../../store/actions/UsersActions';
import { getUserLogged } from '../../store/actions/LoggedActions';
import { getQueryParams } from '../../utils/utils';

import WebButton from '../../components/Common/Button/WebButton';
import { Alert, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import '../../assets/Login.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class LoginContainer extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.channel = postal.channel();
    this.state = {
      contentError: false,
      contentMessage: '',
      invalid: false,
      username: {
        validate: true,
        value: '',
        invalid: false,
        valid: false,
        message: '',
        error: false,
      },
      password: {
        validate: true,
        value: '',
        invalid: false,
        valid: false,
        message: '',
        error: false,
      },
      alert: {
        type: 'error', // 'success' | 'info' | 'warning' | 'error'
        message: 'Error',
        description: '',
      },
      showAlert: false,
      redirectTo: false,
      redirectPath: '',
      error: false,
      loadder: false,
      queryParams: getQueryParams(),
    };
  }

  componentDidMount() {
    const UI = this.props.match?.params?.UI || undefined;
    const uiRegex = /^[A-Za-z0-9-]*/;
    if (UI !== undefined && !localStorage.getItem('token') && uiRegex.test(UI) && UI.length > 25 && UI.length < 50) {
      const data = {
        ui: UI,
        redirect: this.state.redirectPath,
      };

      const { IBLE_P_CAMPO, IBLE_P_COD_ACCION, IBLE_P_EVENTO } = this.state.queryParams;

      if (IBLE_P_CAMPO && IBLE_P_COD_ACCION && IBLE_P_EVENTO) {
        const encrypted = encrypt(JSON.stringify(data));
        this.props.dispatch(userAuthByUrl({ data: encrypted }));
        this.setState({
          ...this.state,
          loadder: true,
        });
      }
      return;
    }

    const enp_id = localStorage.getItem('enp_id');
    const NOMBRE = localStorage.getItem('NOMBRE');

    const setCondition = window.location.href.includes('/pagebuilder')
      ? enp_id && window.location.href.split('?')[1]
      : enp_id && window.location.href.split('?')[1] && getQueryParams().token;

    if (setCondition) {
      const href = window.location.href;
      if (window.location.search.indexOf('search') > -1) {
        const _domain = window.location.search.split('=')[1];
        const dominio = `${window.location.protocol}//${_domain}?token=${localStorage.getItem(
          'enp_id',
        )}&name=${NOMBRE}`;
        window.location.href = dominio;
      } else {
        let url = '';
        if (href.split('?')[2]) {
          if (href.split('?')[2].indexOf('token=') > -1) {
            url = `${window.location.protocol}//${window.location.host}${href.split('?')[1]}?${href.split('?')[2]}`;
          } else {
            url = `${window.location.protocol}//${window.location.host}${href.split('?')[1]}?${
              href.split('?')[2]
            }&token=${enp_id}`;
          }
        } else {
          url = `${window.location.protocol}//${window.location.host}${href.split('?')[1]}?token=${enp_id}`;
        }
        window.location.href = url;
      }
    } else if (!enp_id && window.location.href.split('?')[1] && !getQueryParams().loginAndRedirectTo) {
      localStorage.setItem('redirect', window.location.href.split('?')[1]);
    }
    document.getElementsByTagName('html')[0].classList.add('not-logged');
  }

  componentDidUpdate() {
    if (
      objectPath.get(this.props, 'logged.logged.username', false) &&
      !this.state.redirectTo &&
      objectPath.get(this.props, 'logout.logout.path') === undefined
    ) {
      this.setState({
        ...this.state,
        redirectTo: '/',
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: {
        value: event.target.value,
        validate: false,
        invalid: false,
        error: false,
        valid: false,
      },
      invalid: false,
      contentError: this.state.contentError ? false : this.state.contentError,
    });
  };

  handleMessage = (newUser) => {
    const status = objectPath.get(newUser, 'error.response.status') !== undefined ? newUser.error.response.status : 200;
    if (status === 200) {
      return newUser.error.message;
    } else {
      return 'Por favor, reintente más tarde';
    }
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    const oldUser = this.props.user;
    const newUser = newProps.user;
    if (newUser.success && newUser.user.path && !newUser.error) {
      if (!newProps.logged.logged.username && newProps.logged.init !== true) {
        this.props.dispatch(getUserLogged());
      } else {
        this.setState({ redirectTo: newUser.user.path });
      }
    } else if (oldUser.init && !newUser.init && newUser.error) {
      this.setState({
        error: true,
        showAlert: true,
        loadder: false,
        alert: {
          type: 'error',
          message: 'Error',
          description: this.handleMessage(newUser),
        },
        redirectTo:
          objectPath.get(newUser, 'error.data.path') !== undefined && newUser.error.data.path !== '/auth/login'
            ? newUser.error.data.path
            : false,
      });
    }
  }

  setID(data) {
    this.props.dispatch(getUserLogged());
    return new Promise(() => setTimeout(() => encrypt(JSON.stringify(data)), 3000));
  }

  handleSubmitClick = async () => {
    const data = {
      username: this.state.username.value,
      password: this.state.password.value,
      redirect: this.state.redirectPath,
      user_zone: moment().format('Z')
    };

    if (!data.username || !data.password) {
      this.setState({
        showAlert: true,
        alert: {
          type: 'warning',
          message: '',
          description: 'Ingrese su usuario y password',
        },
      });
      return;
    }

    localStorage.setItem('cod_user', this.state.username.value);
    const id = localStorage.getItem('id');

    /* si no hay id setearlo es necesario porque es usado por encrypt  */
    if (!id) {
      this.props.dispatch(getUserLogged());
      await new Promise((resolve) => setTimeout(resolve, 200));
    }

    const encrypted = encrypt(JSON.stringify(data));
    this.props.dispatch(userAuth({ data: encrypted }));
    this.setState({
      loadder: true,
    });
    document.getElementsByTagName('html')[0].classList.remove('not-logged');
  };

  render() {
    const { username, password, alert, showAlert, loadder } = this.state;
    if (this.state.redirectTo) {
      document.getElementsByTagName('html')[0].classList.remove('not-logged');
      let queryParams = getQueryParams();
      queryParams = queryParams.loginAndRedirectTo ? `?loginAndRedirectTo=${queryParams.loginAndRedirectTo}` : '';
      return <Navigate replace to={this.state.redirectTo + queryParams} />;
    } else {
      document.getElementsByTagName('html')[0].classList.add('not-logged');
    }
    return (
      <LoginForm
        {...{
          username,
          password,
          alert,
          showAlert,
          loadder,
          handleSubmitClick: this.handleSubmitClick,
          handleChange: this.handleChange,
        }}
      />
    );
  }
}

export const LoginForm = (props) => {
  const { username, password, alert, showAlert, loadder, handleSubmitClick, handleChange } = props;
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const delay = setTimeout(() => {
      setShowForm(true);
    }, 300);

    return () => clearTimeout(delay);
  }, []);

  if (!showForm) {
    return null;
  }

  return (
    <>
      <div className="form-group">
        <label htmlFor="username">Usuario</label>
        <Input
          invalid={username.invalid}
          valid={username.valid}
          value={username.value}
          type="text"
          name="username"
          id="username"
          onChange={handleChange}
        />
        {username.error && <FormFeedback>{username.message}</FormFeedback>}
      </div>
      <div className="form-group">
        <label htmlFor="password">Contraseña</label>
        <Input
          invalid={password.invalid}
          valid={password.valid}
          value={password.value}
          type="password"
          name="password"
          id="password"
          onChange={handleChange}
          required="required"
        />
        {password.error && <FormFeedback>{password.message}</FormFeedback>}
      </div>
      <div className="form-group">{showAlert && <Alert {...alert} closable showIcon />}</div>
      <div className="form-group">
        {loadder ? (
          <div className="login-loader">
            {' '}
            <Spin indicator={antIcon} />
          </div>
        ) : (
          <WebButton
            disabled={username.valid && password.valid}
            handleOnClick={handleSubmitClick}
            className="login-btn"
          />
        )}
        <Link to={'/auth/recover/password'}>
          <div className="recover_pass">Olvidé mi contraseña</div>
        </Link>
      </div>
    </>
  );
};

export const Login = (props) => {
  const [showButton, setShowButton] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  useEffect(() => {
    const hostnameWithPort = `${window.location.hostname}:${window.location.port}`;
    const hostname = window.location.hostname;
    const redirectConfig = JSON.parse(process.env.REACT_APP_SSO_DOMAIN || '[]');
    const match = redirectConfig.find((config) => config[hostname] || config[hostnameWithPort]);

    if (match) {
      setRedirectUrl(Object.values(match)[0]);
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, []);

  const handleRedirect = () => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  return (
    <>
      <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 login" id="login">
        <img src="/img/logo_evolutia_horizontal.png" alt="Evolutia Logo" className="login-logo" />
        <div className="form-login" autoComplete="off">
          <h1>Iniciar sesión</h1>
          {!showButton && (
            <>
              <LoginContainer {...props} />
            </>
          )}
          {showButton && (
            <div>
              <div className="message-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icons-tabler-outline icon-tabler-info-square-rounded"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 9h.01" />
                  <path d="M11 12h1v4h1" />
                  <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                </svg>
                Ahora podrás conectarte a Evolutia con tu usuario y contraseñas de red de DTV, la clave que utilizaste
                hasta ahora para acceder de esta aplicacion fue reemplazada por el acceso corporativo. Haz click en el siguiente botón
                para registrarte
              </div>
              <button onClick={handleRedirect} className="button-redirect">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="icon icon-tabler icons-tabler-filled icon-tabler-key"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M14.52 2c1.029 0 2.015 .409 2.742 1.136l3.602 3.602a3.877 3.877 0 0 1 0 5.483l-2.643 2.643a3.88 3.88 0 0 1 -4.941 .452l-.105 -.078l-5.882 5.883a3 3 0 0 1 -1.68 .843l-.22 .027l-.221 .009h-1.172c-1.014 0 -1.867 -.759 -1.991 -1.823l-.009 -.177v-1.172c0 -.704 .248 -1.386 .73 -1.96l.149 -.161l.414 -.414a1 1 0 0 1 .707 -.293h1v-1a1 1 0 0 1 .883 -.993l.117 -.007h1v-1a1 1 0 0 1 .206 -.608l.087 -.1l1.468 -1.469l-.076 -.103a3.9 3.9 0 0 1 -.678 -1.963l-.007 -.236c0 -1.029 .409 -2.015 1.136 -2.742l2.643 -2.643a3.88 3.88 0 0 1 2.741 -1.136m.495 5h-.02a2 2 0 1 0 0 4h.02a2 2 0 1 0 0 -4" />
                  </svg>
                </div>
                <div>Login con SSO</div>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 cover-column">
        <div className="cover-image"></div>
        <div className="elementor-background-overlay"></div>
      </div>
    </>
  );
};

const mapStateToPropsState = (state) => {
  return {
    user: state.user,
    logged: state.logged,
    logout: state.logout,
  };
};

export default connect(mapStateToPropsState)(Login);
