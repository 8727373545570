import React, { useEffect, useState, lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes as RouteSet,
  Navigate,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserLogged } from './store/actions/LoggedActions';
import objectPath from 'object-path';
import { isProductionEnv } from './utils/helper';

import Login from './pages/Login';

const UrlEvent = lazy(() => import('./components/UrlEvent'));
const Layout = lazy(() => import('./layout'));
const RecoverPassword = lazy(() => import('./pages/RecoverPassword'));
const ChangePassword = lazy(() => import('./pages/ChangePassword'));
const Logout = lazy(() => import('./pages/Logout'));
const ExcelUploader = lazy(() => import('./components/ExcelUploader/ExcelUploader'));
const TestServer = lazy(() => import('./components/TestServer/TestServer'));
const Health = lazy(() => import('./pages/Health'));
const DEBUG = isProductionEnv();

const Routes = ({ dispatch, logged }) => {
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    dispatch(getUserLogged());
  }, [dispatch]);

  useEffect(() => {
    const username = objectPath.get(logged, 'logged.username');
    if (username !== undefined && !isLogged) {
      setIsLogged(true);
    }
  }, [logged, isLogged]);

  const TestRoute = () => (
    !DEBUG ? (
      <Route
        path="/test/server"
        element={(
          <Suspense fallback={<div></div>}>
            <TestServer />
          </Suspense>
        )}
      />
    ) : null
  );

  const RenderIfLogged = () => {
    const username = objectPath.get(logged, 'logged.username');
    if (username) {
      return (
        <Route
          path="/*"
          element={(
            <Suspense fallback={<div></div>}>
              <Layout />
            </Suspense>
          )}
        />
      );
    } else if (objectPath.get(logged, 'success') || objectPath.get(logged, 'error')) {
      return <Route
      path="/*"
      element={(
        <Navigate to={'/auth/login/*'} replace />
      )}
    />;
    }
  };

  if (localStorage.getItem('enp_id') && localStorage.getItem('redirect')) {
    if (window.location.search.indexOf('search') > -1) {
      const _domain = window.location.search.split('=')[1];
      const dominio = `${window.location.protocol}//${_domain}?token=${localStorage.getItem(
        'enp_id',
      )}&name=${localStorage.getItem('NOMBRE')}`;
      window.location.href = dominio;
    } else {
      const href = window.location.href;
      const prePath = `${window.location.protocol}//${window.location.host}`;
      let redirectTo = '/';
      const splitedUrl = href.split('?');
      if (splitedUrl[2]) {
        if (href.split('?')[2].indexOf('token=') > -1) {
          redirectTo = `${prePath}${href.split('?')[1]}?${href.split('?')[2]}`;
        } else {
          redirectTo = `${prePath}${href.split('?')[1]}?${href.split('?')[2]}&token=${localStorage.getItem(
            'enp_id',
          )}`;
        }
      } else {
        if (href.split('?')[1].indexOf('token=') > -1) {
          redirectTo = `${prePath}${href.split('?')[1]}`;
        } else {
          redirectTo = `${prePath}${href.split('?')[1]}?token=${localStorage.getItem('enp_id')}`;
        }
      }
      window.location.href = redirectTo;
      localStorage.setItem('redirect', '');
    }
  } else {
    return (
      <Router>
        <RouteSet>
          <Route
              path="/__MON__"
              element={
                <Suspense fallback={<div></div>}>
                  <Health />
                </Suspense>
              }
          />
          <Route
            path="/auth/login/:UI/event/*"
            element={
                <Suspense fallback={<div></div>}>
                  <UrlEvent />
                </Suspense>
            }
          />
          <Route path="/auth/login/*" element={<Login/>} />
          <Route
            path="/auth/logout/*"
            element={
                <Suspense fallback={<div></div>}>
                  <Logout />
                </Suspense>
            }
          />
          <Route
            path="/auth/recover/password/*"
            element={
                <Suspense fallback={<div></div>}>
                  <RecoverPassword />
                </Suspense>
            }
          />
          <Route
            path="/auth/change/password/*"
            element={
                <Suspense fallback={<div></div>}>
                  <ChangePassword />
                </Suspense>
            }
          />
          {TestRoute()}
          <Route
            path="/uploader/*"
            element={(
              <Suspense fallback={<div></div>}>
                <ExcelUploader />
              </Suspense>
            )}
          />
          {RenderIfLogged()}
        </RouteSet>
      </Router>
    );
  }
};

const mapStateToProps = (state) => ({
  logged: state.logged,
  user: state.user,
});

export default connect(mapStateToProps)(Routes);
